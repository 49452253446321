const errors = {
  bad_permission:
    "I'm sorry, you tried to reach a page or an object that you do not have permission to access. If you reached this page via a bookmark, please delete your bookmark, navigate to the page that you are trying to reach, and then re-bookmark the page. If you reached this page by manually typing in a URL, you may have entered an incorrect number or the number may have changed in our records. We encourage users to use the Alarm.com site links rather than typing in URLs for this reason.",
  cookies_off:
    'It appears that cookies are not enabled on your computer. Although Alarm.com does not store any personal information on your computer, your browser must have cookies enabled in order to maintain your secured identity while you are logged in.',
  logout: 'You have successfully logged out.',
  no_session:
    "I'm sorry, your session has automatically timed out as a security precaution - please log in again.",
  scripting_off:
    "It appears that your browser's current security settings prevent JavaScript from working correctly.  Alarm.com uses JavaScript to add functionality to our web pages and make them easier to use.",
  sensors_cmd_sent:
    'A request has been sent to your system to retrieve an up-to-date sensor list.  It may take up to 2-3 minutes for this process to be completed.  After a few moments, please try to log in again.',
  web_error:
    "I'm sorry, an unexpected error occurred while processing the page you requested. As a security precaution you have been automatically logged out.  If the problem persists after you log back into the site, please contact us for help in resolving this problem.",
  lockout:
    "As a security precaution your account has been locked out due to too many incorrect password attempts.  To unlock your account, please request a new password by filling out the 'Forgot your password' form.",
  terminated:
    'Your account has been terminated.  If you believe this to be in error, or if you would like to re-activate your account, please contact us.',
  no_panel:
    'Your control panel has not communicated with Alarm.com. Please make sure your control panel is powered on then try again. If you are still having trouble, please contact us.',
  error:
    "We're sorry, your account is currently unavailable. A message has automatically been sent to Technical Support to address this issue. We will contact you when the issue has been resolved. ",
  no_match:
    "I'm sorry, the login and/or password you entered were not correct. If you have forgotten your login or password, please contact us.",
  remember_me:
    'In order to take advantage of Alarm.com\'s "Remember My Login" feature, you must allow cookies to be stored on your computer.  Visit the Alarm.com FAQ for more information.',
};

export default errors;
