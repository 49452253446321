import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import HomeMonitoringContainer from 'components/HomeMonitoringContainer/HomeMonitoringContainer';
import PreFooter from 'components/PreFooter/PreFooter';

const HomeMonitoringPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.homeMonitoringYaml.title} />
      <HomeMonitoringContainer
        data={data.homeMonitoringYaml.homeMonitoringContainer}
      />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default HomeMonitoringPage;

export const pageQuery = graphql`
  query HomeMonitoringPageQuery {
    homeMonitoringYaml {
      id
      title
      homeMonitoringContainer {
        title
        subtitle
        description
        forgotPasswordLink {
          text
          url
        }
        form {
          submitTitle
          formFields {
            name
            label
            type
            placeholder
            required
            icon {
              file {
                url: publicURL
              }
            }
          }
        }
      }
    }
  }
`;
