import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import Form from './Form/Form';
import { RegularLink } from 'components/Shared/Link';
import './HomeMonitoringContainer.scss';

const HomeMonitoringContainer = ({ data }) => (
  <div className="home-monitoring-container">
    <div className="home-monitoring-container__padding-box">
      <ContentBox>
        <div className="row no-gutters">
          <div className="col-sm-12 col-lg-6">
            <h1 className="home-monitoring-container__title">{data.title}</h1>
            <div className="home-monitoring-container__subtitle">
              {data.subtitle}
            </div>
            <div
              className="home-monitoring-container__description"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </div>
          <div className="col-sm-12 col-lg-6 form-col">
            <Form data={data} />
            <div className="home-monitoring-container__forgot-password">
              <RegularLink to={data.forgotPasswordLink.url}>
                {data.forgotPasswordLink.text}
              </RegularLink>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  </div>
);

export default HomeMonitoringContainer;
