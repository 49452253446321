import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Location } from '@reach/router';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { PrimaryButton } from 'components/Shared/Button/Button';
import FormField from 'components/Shared/Form/FormField/FormField';
import { ALARM_LOGIN_URL } from 'components/Shared/Constants';
import Validators, {
  composeValidators,
  prepareErrors,
} from 'components/Shared/Form/validators';
import errors from './errors';
import './Form.scss';

const ALARM_COOKIE_LOGIN = 'alarmLogin';
const ALARM_COOKIE_TEST = 'cookieTest';

class Form extends Component {
  constructor(props) {
    super(props);
    this.jsTest = null;
    this.cookieTest = null;
    this.decorators = [];
    this.validators = {};
    this.state = {
      submitError: null,
    };
    var self = this;
    this.props.data.form.formFields.forEach(field => {
      var validators = [];
      const messages = field.fieldErrors
        ? prepareErrors(field.fieldErrors)
        : {};
      self.validators[field.name] = [];
      if (field.validators) {
        field.validators.forEach(validator => {
          if (validator in Validators) {
            validators.push(Validators[validator](messages[validator]));
          }
        });
      }
      if (field.required) {
        validators.push(Validators.required(messages['required']));
      }
      self.validators[field.name] = composeValidators(...validators);
      const alarmLogin = Cookies.get(ALARM_COOKIE_LOGIN);
      this.initialValues = alarmLogin
        ? { login: alarmLogin, rememberMe: true }
        : { rememberMe: false };
    });
  }

  componentDidMount() {
    const params = this.props.location.search
      ? queryString.parse(this.props.location.search)
      : {};
    if ('cm' in params) {
      this.setState({ submitError: params.cm });
    } else if ('r' in params) {
      this.setState({ submitError: errors[params.r] });
    } else if ('m' in params) {
      this.setState({ submitError: errors[params.m] });
    }
  }

  testClientCookies() {
    let testPersistant = false;
    if (arguments.length > 0) {
      testPersistant = arguments[0];
    }
    if (testPersistant) {
      // save a new cookies with an expiration date set to 5 seconds from now
      let now = new Date();
      let cookiedate = new Date(now.valueOf() + 1000 * 5);
      Cookies.set(ALARM_COOKIE_TEST, 'persist', { expires: cookiedate });
    } else {
      // save a session cookie (no expiration specified = lives for the
      // lifetime of the browser) so that we check for any kind of cookies
      Cookies.set(ALARM_COOKIE_TEST, 'session');
    }
    if (!Cookies.get(ALARM_COOKIE_TEST)) {
      // cookie not found; all cookies must be disabled
      return false;
    } else {
      return true;
    }
  }

  // function of client-side check(s) that are made when the user tries to log in
  doLoginTimeChecks() {
    if (this.testClientCookies()) {
      this.cookieTest.value = '1';
    }
    this.jsTest.value = '1';
    const values = this.form.state.state.values;
    if (values.login && values.rememberMe !== undefined) {
      if (values.rememberMe) {
        Cookies.set(ALARM_COOKIE_LOGIN, values.login, { expires: 90 });
      } else {
        Cookies.remove(ALARM_COOKIE_LOGIN);
      }
    }
    return true;
  }

  submit = (errors, submit, e) => {
    if (errors) {
      e.preventDefault();
      submit();
    } else {
      this.doLoginTimeChecks();
    }
  };

  render() {
    const { data } = this.props;
    return (
      <FinalForm
        decorators={this.decorators}
        initialValues={this.initialValues}
        ref={form => (this.form = form)}
        onSubmit={() => {}}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form
            className="form-container"
            onSubmit={e => this.submit(hasValidationErrors, handleSubmit, e)}
            action={ALARM_LOGIN_URL}
            method="POST"
            name={data.form.name}
            id={data.form.name}
          >
            {this.state.submitError ? (
              <div className="form-container__error">
                {this.state.submitError}
              </div>
            ) : null}
            <input
              type="hidden"
              name="JavaScriptTest"
              value=""
              ref={el => (this.jsTest = el)}
            />
            <input
              type="hidden"
              name="cookieTest"
              value=""
              ref={el => (this.cookieTest = el)}
            />
            <input
              type="hidden"
              name="loginFolder"
              value={this.props.location.origin + this.props.location.pathname}
            />
            {data.form.formFields.map(field => (
              <div className="form-container__row" key={field.name}>
                <FormField
                  data={field}
                  validate={this.validators[field.name]}
                />
              </div>
            ))}
            <div className="form-container__button-row">
              <PrimaryButton type="submit">
                {data.form.submitTitle}
              </PrimaryButton>
            </div>
          </form>
        )}
      />
    );
  }
}

const FormWithLocation = props => (
  <Location>{locationProps => <Form {...locationProps} {...props} />}</Location>
);

export default FormWithLocation;
